import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../../components/layout";

const NewsPost = ({ data }) => {
  const { title, body, newsId, publishedAt, category } = data.microcmsNews;
  return (
    <Layout id={newsId} pageTitle={title}>
      <p>{publishedAt}</p>
      <p>
        <Link to={`/news/category/${category.slug}`}>{category.name}</Link>
      </p>
      <div>{body}</div>
      <div>
        <Link to="/news">News一覧</Link>
      </div>
    </Layout>
  );
};

export default NewsPost;

export const query = graphql`
  query ($newsId: String) {
    microcmsNews(newsId: { eq: $newsId }) {
      title
      body
      newsId
      publishedAt(formatString: "YYYY.MM.DD")
      category {
        slug
        name
        id
      }
    }
  }
`;
